const contentEn = `
	<p>Earlobes are an important element of overall facial symmetry, which is why many people choose earlobe repair. As a person age, their earlobes age along with them: &bull; Earlobes usually lengthen and appear larger as skin loses elasticity.</p>

	<ul>
		<li>Holes from childhood piercings and scars from surgeries become more noticeable.</li>
		<li>Tears or gaps caused by accidents or illness draw attention away from the face.</li>
	</ul>

	<p>There are a few ways to treat cysts, but minor surgical removal is the most effective. Draining a cyst may work in the short term, but the cyst will likely come back. That&rsquo;s because when a cyst is drained, the cyst&#39;s walls remain in the skin. These walls make the collection of materials within the cyst more likely to recur. Cyst removal surgery seeks to remove the cyst entirely, including the cyst&#39;s walls, so it cannot grow back.</p>
	`

const contentPtBR = `
<p>Os l&oacute;bulos das orelhas s&atilde;o um elemento importante da simetria facial geral, e &eacute; por isso que muitas pessoas escolhem o reparo do l&oacute;bulo da orelha. &Agrave; medida que uma pessoa envelhece, os l&oacute;bulos das orelhas tamb&eacute;m envelhecem:</p>

<ul>
	<li>Os l&oacute;bulos das orelhas geralmente se alongam e parecem maiores &agrave; medida que a pele perde elasticidade.</li>
	<li>Buracos de piercings na inf&acirc;ncia e cicatrizes de cirurgias tornam-se mais percept&iacute;veis.</li>
	<li>L&aacute;grimas ou lacunas causadas por acidentes ou doen&ccedil;as desviam a aten&ccedil;&atilde;o do rosto.</li>
</ul>

<p>Existem algumas maneiras de tratar cistos, mas a remo&ccedil;&atilde;o cir&uacute;rgica menor &eacute; a mais eficaz. A drenagem de um cisto pode funcionar a curto prazo, mas o cisto provavelmente voltar&aacute;. Isso porque quando um cisto &eacute; drenado, as paredes do cisto permanecem na pele. Essas paredes tornam mais prov&aacute;vel a recorr&ecirc;ncia da cole&ccedil;&atilde;o de materiais dentro do cisto. A cirurgia de remo&ccedil;&atilde;o de cisto visa remover o cisto completamente, incluindo as paredes do cisto, para que n&atilde;o volte a crescer.</p>


`

export default { contentEn, contentPtBR }