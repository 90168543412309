const contentEn = `
<ul>
	<li><strong>The GentleMax Pro</strong> is actually an integration of two laser platforms featuring dual wavelengths, elevating the science of heat and light to a new level of cosmetic and medical laser treatment.</li>
	<li>The system takes the fastest, most powerful 755 nm Alexandrite laser.</li>
	<li>It combines with the incredible performance of the 1064 nm Nd: YAG laser to deliver an efficient, versatile, safe, and effective solution to your problems with a single beam of light.</li>
	<li>Because of the system&rsquo;s revolutionary dual wavelength technology, many of our patients choose to have this fast, effective laser treatment for a wide spectrum of other dermatological issues:</li>
</ul>

<ol>
	<li>Superficial wrinkles, creases, and lines</li>
	<li>Facial &amp; leg veins</li>
	<li>Sun-damaged skin</li>
	<li>Age spots</li>
	<li>Freckles</li>
	<li>Skin Tightening</li>
	<li>Dull skin tone</li>
	<li>Pigmented lesions</li>
	<li>Freckles</li>
	<li>Spider veins</li>
	<li>Nail Fungus</li>
</ol>
`

const contentPtBR = `
<ul>
	<li>O GentleMax Pro &eacute;, na verdade, uma integra&ccedil;&atilde;o de duas plataformas de laser com comprimentos de onda duplos, elevando a ci&ecirc;ncia do calor e da luz a um novo n&iacute;vel de tratamento a laser cosm&eacute;tico e m&eacute;dico.</li>
	<li>O sistema utiliza o laser Alexandrite de 755 nm mais r&aacute;pido e potente.</li>
	<li>Combina com o incr&iacute;vel desempenho do laser Nd:YAG de 1064 nm para fornecer uma solu&ccedil;&atilde;o eficiente, vers&aacute;til, segura e eficaz para seus problemas com um &uacute;nico feixe de luz.</li>
	<li>Devido &agrave; revolucion&aacute;ria tecnologia de comprimento de onda duplo do sistema, muitos de nossos pacientes optam por fazer este tratamento a laser r&aacute;pido e eficaz para uma ampla gama de outros problemas dermatol&oacute;gicos:</li>
	<li>Rugas superficiais, vincos e linhas</li>
	<li>Veias faciais e das pernas</li>
	<li>pele danificada pelo sol</li>
	<li>manchas de idade</li>
	<li>Sardas</li>
	<li>endurecimento da pele</li>
	<li>tom de pele ma&ccedil;ante</li>
	<li>Les&otilde;es pigmentadas</li>
	<li>Sardas</li>
	<li>Veias de aranha</li>
</ul>

`

export default { contentEn, contentPtBR }