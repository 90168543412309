const contentEn = `
<p>Platelet Rich Plasma (PRP) treatment is a process where special healing growth factors are harvested from your blood that can then be delivered to damaged tissue.</p>
<ul>
    <li>PRP can enhance collagen and elastin production in deeper skin levels.</li>
    <li>Microneedling with PRP effectively treats acne scars, surgical scars, wrinkles, fine lines, hyperpigmentation, sun damage, large pores, and uneven skin texture irregularities.</li>
    <li>It helps to decrease the appearance of stretch marks.</li>
</ul>
<p>An energizing procedure that incorporates the Signature Hydrafacial&#39;s core components while targeting your particular skin issues with a Booster of your choosing. To further lessen the outward indications of aging, this procedure is finished with LED light therapy. Contains a serum to assist in addressing your individual skin needs.</p>
`

const contentPtBR = `
<p>O tratamento com Plasma Rico em Plaquetas (PRP) &eacute; um processo em que fatores de crescimento curativos especiais s&atilde;o colhidos do seu sangue, que podem ent&atilde;o ser entregues ao tecido danificado.</p>
<ul>
	<li>O PRP pode aumentar a produ&ccedil;&atilde;o de col&aacute;geno e elastina em n&iacute;veis mais profundos da pele.</li>
	<li>O microagulhamento com PRP trata efetivamente cicatrizes de acne, cicatrizes cir&uacute;rgicas, rugas, linhas finas, hiperpigmenta&ccedil;&atilde;o, danos causados pelo sol, poros dilatados e irregularidades na textura irregular da pele.</li>
	<li>Ajuda a diminuir o aparecimento de estrias.</li>
</ul>
<p>Um procedimento energizante que incorpora os principais componentes da assinatura hidrafacial enquanto aborda seus problemas de pele espec&iacute;ficos com um refor&ccedil;o de sua escolha. Para diminuir ainda mais as indica&ccedil;&otilde;es externas de envelhecimento, este procedimento &eacute; finalizado com terapia de luz LED. Cont&eacute;m um soro para ajudar a atender &agrave;s necessidades individuais de sua pele.</p>
`

export default { contentEn, contentPtBR }