<template>
    <footer class="site-footer">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-12 mb-4">
                    <img src="/images/logo.png" class="logo img-fluid" alt="">
                </div>

                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <h5 class="site-footer-title mb-3">{{ $t('footer.quickLinks') }}</h5>

                    <ul class="footer-menu">
                        <li class="footer-menu-item">
                            <router-link activeClass="active-link" :to="{ name: 'Home'}">
                                <a href="#" class="footer-menu-link">{{ $t('links.home') }}</a>
                            </router-link>
                        </li>
                        <li class="footer-menu-item">
                            <router-link activeClass="active-link" :to="{ name: 'Mission'}">
                                <a href="#" class="footer-menu-link">{{ $t('links.mission') }}</a>
                            </router-link>
                        </li>
                        <li class="footer-menu-item">
                            <router-link :to="{ name: 'Home'}" v-scroll-to="'#section_services'">
                                <a href="#" class="footer-menu-link">{{ $t('links.services') }}</a>
                            </router-link>
                        </li>
                        <li class="footer-menu-item">
                            <router-link :to="{ name: 'Home'}" v-scroll-to="'#section_contact'">
                                <a href="#" class="footer-menu-link">{{ $t('links.contact') }}</a>
                            </router-link>
                        </li>
                        <li class="footer-menu-item">
                            <router-link activeClass="active-link" :to="{ name: 'Privacy Policy'}">
                                <a href="#" class="footer-menu-link">{{ $t('links.privacy') }}</a>
                            </router-link>
                        </li>
                        <li class="footer-menu-item">
                            <a 
                                href="https://diversecarenow.as.me/schedule/6a240cde"
                                class="footer-menu-link"
                                target="_blank">
                                {{ $t('footer.bookAppointment') }}
                                </a>
                        </li>
                    </ul>
                </div>

                <div class="col-lg-4 col-md-6 col-12 mx-auto">
                    <h5 class="site-footer-title mb-3">{{ $t('contact.title') }}</h5>

                    <p class="text-white d-flex mb-2">
                        <i class="bi-instagram me-2"></i>
                        <a 
                            href="https://www.instagram.com/diversecarenow/?igshid=YmMyMTA2M2Y%3D" 
                            target="_blank"
                            class="site-footer-link">
                            @diversecarenow
                        </a>
                    </p>
                    <p class="text-white d-flex mb-2">
                        <i class="bi-telephone me-2"></i>

                        <a href="tel:+17547521888" class="site-footer-link">
                            754.752.1888
                        </a>
                    </p>
                    <p class="text-white d-flex">
                        <i class="bi-envelope me-2"></i>

                        <a href="mailto:info@diversecarenow.com" class="site-footer-link">
                            info@diversecarenow.com
                        </a>
                    </p>

                    <p class="text-white d-flex mt-3">
                        <i class="bi-geo-alt me-2"></i>
                        <a 
                            href="https://goo.gl/maps/Y49yuc6wRG2yyR4JA" 
                            target="_blank"
                            class="site-footer-link">
                            2700 NE 14TH ST#101 - POMPANO BEACH-FL 33062
                        </a>
                    </p>

                </div>
            </div>
        </div>

        <div class="site-footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-5 col-12 d-flex justify-content-center align-items-center mx-auto">
                        <ul class="social-icon">
                            <li class="social-icon-item">
                                <a 
                                    href="https://www.instagram.com/diversecarenow/?igshid=YmMyMTA2M2Y%3D"
                                    target="_blank"
                                    class="social-icon-link bi-instagram">
                                </a>
                            </li>
                        </ul>
                    </div>
                    
                </div>
                <br />
                <div class="row">
                    <div class="col-lg-6 col-md-5 col-12 d-flex justify-content-center align-items-center mx-auto">
                        <ul class="social-icon">
                            <li class="social-icon-item">
                                Copyright © {{ year }} - v{{ version }}
                            </li>
                        </ul>
                    </div>
                    
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
import { version } from '../../package'
export default {
  name: 'FooterLayout',
  setup() {
        const year = new Date().getFullYear()
        return { version, year }
    }
}
</script>