const contentEn = `
<ul>
    <li>Treating acute illnesses and injuries</li>
    <li>Managing chronic illness and coordinating complex care with specialists</li>
    <li>Nutrition and weight loss care</li>
    <li>Pediatric and adolescent care</li>
</ul>`

const contentPtBR = `
<ul>
	<li>Covid 19 e preven&ccedil;&atilde;o</li>
	<li>Sequelas pos covid</li>
	<li>Doen&ccedil;as contagiosas, gripe, viroses e alergias</li>
	<li>Depress&atilde;o e ansiedade</li>
	<li>Tratamentos hormonais</li>
	<li>Obesidade e perda de peso</li>
	<li>Ozempic, adipex, hcg, mounjaro, naltrexone, wegovy e saxenda</li>
	<li>Diabetes, press&atilde;o alta, colesterol, anemia, infec&ccedil;oes e atendimentos ginecologicos</li>
	<li>Prescri&ccedil;&atilde;o de medicamentos incluindo controlados</li>
	<li>Refils de medicamentos</li>
	<li>Check-ups - servi&ccedil;os laboratoriais parceria nacional com pre&ccedil;os especiais;</li>
	<li>Atestados, formul&aacute;rios, cartas e permiss&otilde;es</li>
</ul>

`

export default { contentEn, contentPtBR }