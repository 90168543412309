const contentEn = `<p><strong>Dermal fillers</strong> (also called cosmetic fillers or soft tissue fillers) are a non-surgical, injectable treatment performed by our expert injectors to:</p>
<ul>
	<li>Restore lost facial volume,</li>
	<li>Create youthful facial contours,</li>
	<li>Add volume to lips and</li>
	<li>Restore volume to the back of hands. Restoring lost volume smooths out and reduces the appearance of fine lines, wrinkles, and folds.* With dermal fillers, you get immediate results with no downtime. Results typically last 6-18 months.*</li>
</ul>
<p><strong>Polydioxanone (PDO) thread lift</strong> is a procedure that uses a dissolvable suture to tighten and lift your skin.</p>

<ul>
	<li>It&rsquo;s a less invasive procedure than facelift surgery and can often be performed in under 45 minutes without needing to go under a scalpel.</li>
	<li>Polydioxanone (PDO) thread lifts use a biodegradable polyester suture. They&rsquo;re best suited for rejuvenating your skin, while some newer types of thread lifts are better at lifting sagging skin.</li>
</ul>

<p><strong>Sculptra </strong>is an injectable cosmetic filler that can be used to restore facial volume lost due to aging or illness.</p>

<ul>
	<li>It contains poly-L-lactic acid (PLLA), a biocompatible synthetic substance that stimulates collagen production.</li>
	<li>It can be used to treat deep lines, creases, and folds to give a more youthful appearance.</li>
	<li>It&rsquo;s also used to treat facial fat loss (lipoatrophy) in people</li>
</ul>
`

const contentPtBR = `
<p>Preenchimentos d&eacute;rmicos (tamb&eacute;m chamados de preenchimentos cosm&eacute;ticos ou preenchimentos de tecidos moles) s&atilde;o um tratamento injet&aacute;vel n&atilde;o cir&uacute;rgico realizado por nossos injetores especializados para:</p>
<ul>
	<li>Restaurar o volume facial perdido,</li>
	<li>Crie contornos faciais jovens,</li>
	<li>Adicione volume aos l&aacute;bios e</li>
	<li>Restaure o volume nas costas das m&atilde;os. Restaurar o volume perdido suaviza e reduz a apar&ecirc;ncia de linhas finas, rugas e dobras.* Com os preenchedores d&eacute;rmicos, voc&ecirc; obt&eacute;m resultados imediatos sem tempo de inatividade. Os resultados geralmente duram de 6 a 18 meses.*</li>
</ul>
<p>O lifting de fio de polidioxanona (PDO) &eacute; um procedimento que usa uma sutura sol&uacute;vel para apertar e levantar a pele.</p>
<p>&Eacute; um procedimento menos invasivo do que a cirurgia de facelift e muitas vezes pode ser realizado em menos de 45 minutos sem a necessidade de passar por um bisturi.</p>
<p>Os elevadores de fios de polidioxanona (PDO) usam uma sutura de poli&eacute;ster biodegrad&aacute;vel. Eles s&atilde;o mais adequados para rejuvenescer a pele, enquanto alguns tipos mais novos de elevadores de fios s&atilde;o melhores para levantar a pele fl&aacute;cida.</p>
<p>Sculptra &eacute; um preenchedor cosm&eacute;tico injet&aacute;vel que pode ser usado para restaurar o volume facial perdido devido ao envelhecimento ou doen&ccedil;a.</p>
<p>Cont&eacute;m &aacute;cido poli-L-l&aacute;tico (PLLA), uma subst&acirc;ncia sint&eacute;tica biocompat&iacute;vel que estimula a produ&ccedil;&atilde;o de col&aacute;geno.</p>
<p>Pode ser usado para tratar linhas profundas, vincos e dobras para dar uma apar&ecirc;ncia mais jovem.</p>
<p>Tamb&eacute;m &eacute; usado para tratar a perda de gordura facial (lipoatrofia) em pessoas.</p>

`

export default { contentEn, contentPtBR }