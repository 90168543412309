export const en = {
    links: {
        home: 'Home',
        mission: 'Mission',
        services: 'Services',
        contact: 'Contact',
        privacy: 'Privacy Policy'
    },
    contact: {
        title: 'Contact Infomation',
        findUs: 'Find us',
        btnWhatsapp: 'btn_whatsapp_en.png'
    },
    img: {
        img1: '1_en2.jpg',
        img2: '2_en2.jpg',
        img3: '3_en2.jpg'
    },
    footer: {
        quickLinks: 'Quick Links',
        bookAppointment: 'Book Appointment',
    },
    altsImgs: {
        btnWhatsapp: 'Start whatsapp chat button.',
        logoDiverse: 'Diverse Care Now'
    }

}
