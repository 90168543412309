const contentEn = `
<ul>
    <li>Medications such as Ozempic, Saxenda, Mounjaro, Phentermine, and HCG will be prescribed to assist in weight loss.</li>
    <li>Benefits of weight loss can include:</li>
    <li>Lower blood pressure</li>
    <li>Lower levels of triglycerides</li>
    <li>Less risk of heart disease</li>
    <li>Improved mobility and reduced pain</li>
    <li>Better sex and less erectile dysfunction</li>
    <li>Improved mood</li>
    <li>Better sleep</li>
    <li>Higher self-esteem</li>
    <li>Improved insulin resistance</li>
    <li>Lower risk for multiple cancers</li>
</ul>
`

const contentPtBR = `
<ul>
	<li>Medicamentos como Ozempic, Saxenda, Mounjaro, Phentermine e HCG ser&atilde;o prescritos para ajudar na perda de peso.</li>
	<li>Os benef&iacute;cios da perda de peso podem incluir:</li>
	<li>Redu&ccedil;&atilde;o da press&atilde;o arterial</li>
	<li>N&iacute;veis mais baixos de triglicer&iacute;deos</li>
	<li>Menor risco de doen&ccedil;a card&iacute;aca</li>
	<li>Mobilidade melhorada e dor reduzida</li>
	<li>Melhor sexo e menos disfun&ccedil;&atilde;o er&eacute;til</li>
	<li>Humor melhorado</li>
	<li>Melhor sono</li>
	<li>Maior auto-estima</li>
	<li>Resist&ecirc;ncia &agrave; insulina melhorada</li>
	<li>Menor risco de v&aacute;rios tipos de c&acirc;ncer</li>
</ul>

`

export default { contentEn, contentPtBR }