const contentEn = `
<p>ENG - DCN ACADEMY CURSES:</p>

<p>FOR PROVIDERS :</p>

<ul>
	<li>BOTOX ;</li>
	<li>FILLERS;</li>
	<li>PDO;</li>
	<li>PELLET;</li>
	<li>IV TECHNICIAN;</li>
</ul>

<p>FOR NEWBIE:</p>

<ul>
	<li>MEDICAL ASSISTANT;</li>
	<li>PHLEBOTOMY;</li>
	<li>EKG TECHNICIAN&nbsp;</li>
</ul>

<p>&mdash;&mdash;&mdash;&mdash;<br />
ESP - CURSOS DE LA ACADEMIA DCN:</p>

<p>PARA PROVEEDORES:</p>

<ul>
	<li>B&Oacute;TOX;</li>
	<li>RELLENOS;</li>
	<li>PDO (HILLOS);</li>
	<li>PELLET (REEMPLAZO HORMONAL) ;</li>
</ul>

<p>PARA NOVATOS:</p>

<ul>
	<li>ASISTENTE M&Eacute;DICO;</li>
	<li>FLEBOTOM&Iacute;A;</li>
	<li>T&Eacute;CNICO DE EKG</li>
	<li>IV T&Eacute;CNICO;</li>
</ul>

`


const contentPtBR = `
<p>PORT - TITULO : DCN ACADEMY CURSOS:</p>

<p>PARA PROVEDORES DA &Aacute;REA DE SA&Uacute;DE :</p>

<ul>
	<li>BOTOX ;</li>
	<li>PREENCHIMENTOS;</li>
	<li>PDO (FIOS) ;</li>
	<li>PELLET;</li>
</ul>

<p>PARA NOVATOS:</p>

<ul>
	<li>ASSISTENTE M&Eacute;DICO;</li>
	<li>FLEBOTOMIA;</li>
	<li>T&Eacute;CNICO DE ECG;</li>
	<li>T&Eacute;CNICO DE IV;</li>
</ul>

`

export default { contentEn, contentPtBR }