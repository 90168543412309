<template>
    <section class="section-padding section-bg section-padding-contact" id="section_contact">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-12 mb-5">
                    <div class="contact-info-wrap">
                        <h2>{{ $t('contact.title') }}</h2>
                        <div class="contact-info">
                            <p class="d-flex mb-2">
                                <i class="bi-geo-alt me-2"></i>

                                <a 
                                    href="https://goo.gl/maps/Y49yuc6wRG2yyR4JA"
                                    target="_blank">
                                    2700 NE 14th Street Causeway, Pompano Beach, FL 33062
                                </a>
                            </p>
                            <p class="d-flex">
                                <i class="bi-envelope me-2"></i>

                                <a href="mailto:info@diversecarenow.com">
                                    info@diversecarenow.com
                                </a>
                            </p>
                            <p class="d-flex mb-2">
                                <i class="bi-instagram me-2"></i>

                                <a 
                                    href="https://www.instagram.com/diversecarenow/?igshid=YmMyMTA2M2Y%3D" 
                                    target="_blank"
                                >
                                    @diversecarenow
                                </a>
                            </p>
                            <p class="d-flex mb-2">
                                <i class="bi-telephone me-2"></i>

                                <a href="tel:+17547521888">
                                    754.752.1888
                                </a>
                            </p>
                            <p class="d-flex mb-2">
                                

                                PLEASE NOTE: AFTER HOURS AND WEEKENDS - 
                                We are only available via text message at:
                            </p>
                            <p class="d-flex mb-2">
                            <i class="bi-chat-text me-2"></i>
                                <a style="" href="sms:+17547521888">(754) 752-1888</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-12 ms-auto">
                    <h2 class="mb-0">{{ $t('contact.findUs') }}</h2>
                    <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3578.36699232508!2d-80.097983384969!3d26.249750083417524!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc093858c1cac6fb0!2zMjbCsDE0JzU5LjEiTiA4MMKwMDUnNDQuOSJX!5e0!3m2!1spt-BR!2sbr!4v1672442523357!5m2!1spt-BR!2sbr" 
                        width="100%" 
                        height="300px" 
                        style="border:0;" 
                        allowfullscreen="" 
                        loading="lazy" 
                        referrerpolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
  name: 'ContactComponent'
}
</script>