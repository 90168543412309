<template>
    <section class="section-padding" id="section_services">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-12 text-center mb-4">
                    <h2>{{ $t("links.services") }}</h2>
                    <hr />
                </div>
                <div class="row">
                    <div
                        v-for="(service, index) in services"
                        :key="index"
                        class="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0 mt-5"
                    >
                        <template v-if="service.nameParams !== 'noLink'">
                            <router-link :to="{ name: 'Service', params: { service: service.nameParams } }">
                                <div class="custom-block-wrap">
                                    <img
                                        :src="`/images/services/${service.img}`"
                                        class="custom-block-image img-fluid"
                                        alt=""
                                    />

                                    <div class="custom-block">
                                        <a href="#" class="custom-btn btn">
                                            {{ $i18n.locale == "pt_BR" ? service.labelPtBR : service.labelEn }}
                                        </a>
                                    </div>
                                </div>
                            </router-link>
                        </template>
                        <template v-else>
                            <a :href="service.linkExternal">
                                <div class="custom-block-wrap">
                                    <img
                                        :src="`/images/services/${service.img}`"
                                        class="custom-block-image img-fluid"
                                        alt=""
                                    />
                                    <div class="custom-block">
                                        <a class="custom-btn btn">
                                            {{ $i18n.locale == "pt_BR" ? service.labelPtBR : service.labelEn }}
                                        </a>
                                    </div>
                                </div>
                            </a>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { servicesContent } from "./../pages/ServicesDetails/ServicesContent";

export default {
    name: "ServicesComponent",
    setup() {
        const services = servicesContent;
        return {
            services,
        };
    },
};
</script>
