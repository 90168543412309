<template>
    <nav id="nav" class="navbar navbar-expand-lg bg-light shadow-lg">
        <div class="container">
            <router-link :to="{ name: 'Home'}">
                <img src="/images/logo.png" class="logo img-fluid" :alt="$t('altsImgs.logoDiverse')">
            </router-link>

            <button class="navbar-toggler btnMenu"  type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item">
                        <router-link activeClass="active-link" :to="{ name: 'Home'}">
                            <a class="nav-link" @click="closeMenu()" href="#top">{{ $t('links.home') }}</a>
                        </router-link >
                    </li>

                    <li class="nav-item">
                        <router-link activeClass="active-link" :to="{ name: 'Mission'}">
                            <a class="nav-link" @click="closeMenu()">{{ $t('links.mission') }}</a>
                        </router-link>
                    </li>

                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" 
                            href="#section_5" 
                            id="navbarLightDropdownMenuLink" 
                            role="button" 
                            data-bs-toggle="dropdown" 
                            data-bs-auto-close="true"
                            aria-expanded="false">
                            {{ $t('links.services') }}
                        </a>

                        <ul class="dropdown-menu dropdown-menu-light" aria-labelledby="navbarLightDropdownMenuLink" >
                            <li v-for="(service, index) in services" :key="index">
                                <router-link activeClass="active-link"  :to="{ name: 'Service', params: { service: service.nameParams }}">
                                    <a class="dropdown-item" @click="closeMenu()" :href="`/service/${service.nameParams}`">
                                        <template v-if="service.id == 5">
                                            {{ $i18n.locale == 'en' ? 'Hormone Replacement' : service.labelPtBR }}
                                        </template>
                                        <template v-else>
                                            {{ $i18n.locale == 'pt_BR' ? service.labelPtBR : service.labelEn  }}
                                        </template>
                                    </a>
                                </router-link>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item">
                        <router-link :to="{ name: 'Home'}" v-scroll-to="'#section_contact'">
                            <a class="nav-link" @click="closeMenu()" href="#section_contact">{{ $t('links.contact') }}</a>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>
<script>
import { servicesContent } from './../pages/ServicesDetails/ServicesContent'

export default {
  name: 'ServicesComponent',
  setup() {
    const services = servicesContent
    const closeMenu = () => {
        let collapse = document.querySelector('.show');
        if(collapse){
            let btnMenu = document.querySelector('.btnMenu');
            btnMenu.click();
        }
    }
    
    return {
        closeMenu,
        services
    }
  }
}
</script>