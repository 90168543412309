<template>
    <header class="site-header">
        <div class="container">
            <div class="row">
                <div class="col align-self-end">
                    <ul class="social-icon">
                        <li class="social-icon-item">
                            <a 
                                href="https://diversecarenow.as.me/schedule/6a240cde"
                                target="_blank"
                                class="social-icon-link bi bi-calendar-event">
                            </a>
                        </li>
                        <li class="social-icon-item">
                            <a 
                                href="https://www.instagram.com/diversecarenow/?igshid=YmMyMTA2M2Y%3D"
                                target="_blank"
                                class="social-icon-link bi-instagram">
                            </a>
                        </li>
                        <li class="social-icon-item">
                            <div class="language" style="margin-left:20px;">
                                <div class="img-bandeira">
                                    <img src="/images/bandeira_eua.png" class="custom-block-image img-fluid" alt="Language Inglês">
                                </div> 
                                <div class="form-check form-switch">
                                    <input 
                                        class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" 
                                        @change="setLocale()">
                                </div>
                                <div class="img-bandeira">
                                    <img src="/images/bandeira_brasil.png" class="custom-block-image img-fluid" alt="Idioma Português">
                                </div> 
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </header>
</template>
<script>
    import { useI18n } from 'vue-i18n';
    
    export default({
        setup() {
            const { locale } = useI18n({ useScope: 'global' })
            let idiomaAtual = 'en'

            const setLocale = () => {
                idiomaAtual = idiomaAtual == 'en' ? 'pt_BR' : 'en'
                locale.value = idiomaAtual
            }

            return {
                setLocale,
                locale
            }
        }
    })
</script>