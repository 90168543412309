const contentEn = `
<ul>
	<li><strong> A Sculptra butt lift </strong>works differently than other kinds of dermal fillers.</li>
	<li>Instead of adding volume outright like hyaluronic acid ingredients, sculptra injects something called poly-L-lactic acid into your dermal layers. This substance works to kick-start your body&rsquo;s natural collagen production mechanism.</li>
	<li>Since collagen is the protein that gives your skin its structure and shape, sculptra injections fill the area under your butt with a curved shape that looks natural and fits your body type.</li>
	<li>The building of collagen takes several weeks to months. This means you&rsquo;ll likely need several injections to see improvement in volume and shape.</li>
</ul>
`

const contentPtBR = `
<ul>
	<li>Um elevador de bumbum Sculptra funciona de maneira diferente de outros tipos de preenchimentos d&eacute;rmicos.&nbsp;</li>
	<li>Em vez de adicionar volume diretamente como ingredientes de &aacute;cido hialur&ocirc;nico, o sculptra injeta algo chamado &aacute;cido poli-L-l&aacute;ctico em suas camadas d&eacute;rmicas. Esta subst&acirc;ncia funciona para iniciar o mecanismo natural de produ&ccedil;&atilde;o de col&aacute;geno do seu corpo.&nbsp;</li>
	<li>Uma vez que o col&aacute;geno &eacute; a prote&iacute;na que d&aacute; estrutura e forma &agrave; sua pele, as inje&ccedil;&otilde;es de sculptra preenchem a &aacute;rea sob o bumbum com uma forma curva que parece natural e se ajusta ao seu tipo de corpo.&nbsp;</li>
	<li>A forma&ccedil;&atilde;o de col&aacute;geno leva v&aacute;rias semanas a meses. Isso significa que voc&ecirc; provavelmente precisar&aacute; de v&aacute;rias inje&ccedil;&otilde;es para ver a melhora no volume e na forma.</li>
</ul>
`

export default { contentEn, contentPtBR }