<template>
    <section class="section-padding section-bg" id="section_2">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-12">
                    <div class="custom-text-box">
                        <h2 class="mb-2">{{ $t('links.mission') }}</h2>
                        <template v-if="$i18n.locale == 'en'">
                            <p>Diverse Care Now is a family medical practice that provides general medical care, gynecological care, anti-aging medicine, and aesthetic enhancements. Our mission is to improve the lives of all families, children, and the community by delivering the highest quality of care. Our Team is multilingual (Portuguese, Spanish, and English). The team at Diverse Care Now take pride in treating each patient as if they were a member of our family. We take the time to listen and address patients&#39; concerns while helping them reach their health goals. Helping our patients achieve optimal health, rejuvenation, and quality of life is our top priority. As family medical practice, we offer:</p>
                            <ul>
                                <li>Affordable consultations</li>
                                <li>School physicals</li>
                                <li>Women&rsquo;s health</li>
                                <li>Men&rsquo;s health</li>
                                <li>Ear lobe repair</li>
                                <li>Cyst</li>
                            </ul>
                            <p>We aim to help our patients achieve better vitality, and be better than when they walked through our doors. Patients come from all over Florida for our treatments and various other procedures&mdash; because we begin at the cellular level to revitalize our patient&rsquo;s life.</p>
                        </template>
                        <template v-else>
                            <p>A Diverse Care Now &eacute; uma cl&iacute;nica m&eacute;dica familiar que oferece cuidados m&eacute;dicos gerais, ginecol&oacute;gicos, medicina antienvelhecimento e melhorias est&eacute;ticas.</p>
                            <p>Nossa miss&atilde;o &eacute; melhorar a vida de todas as fam&iacute;lias, crian&ccedil;as e a comunidade, oferecendo cuidados da mais alta qualidade.</p>
                            <p>Contamos com uma equipe que &eacute; multil&iacute;ngue (portugu&ecirc;s, espanhol e ingl&ecirc;s) preparada para atender a todos os nossos pacientes .</p>
                            <p>A equipe da Diverse Care Now se orgulha de tratar cada paciente como se fosse um membro da nossa fam&iacute;lia. N&oacute;s dedicamos tempo para ouvir e alcan&ccedil;ar a sa&uacute;de ideal, rejuvenescimento e qualidade de vida &eacute; a nossa principal prioridade.</p>
                            <p>Abordamos as preocupa&ccedil;&otilde;es dos pacientes enquanto os ajudamos a alcan&ccedil;ar seus objetivos de sa&uacute;de, vitalidade e sa&iacute;rem melhores do que quando passaram por nossas portas.</p>
                            <p>Os pacientes v&ecirc;m de toda a Fl&oacute;rida e outras partes do pa&iacute;s para nossos tratamentos e v&aacute;rios outros procedimentos que v&atilde;o desde o n&iacute;vel celular.</p>
                        </template>
                    </div>
                </div>
                <div class="col-lg-6 col-12 mb-5 mb-lg-0">
                    <img src="images/mission2.jpeg" class="custom-text-box-image img-fluid" alt="">
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
  name: 'MissionPage'
}
</script>