<template>
    <body>
        <div class="header-fixed">
            <HeaderLayout/>
            <NavLayout/>
        </div>
        <main class="main-padding">
            <router-view></router-view>
        </main>
        <FooterLayout/>

    </body>
</template>
<script>
import HeaderLayout from './HeaderLayout'
import NavLayout from './NavLayout'
import FooterLayout from './FooterLayout'

export default {
  name: 'App',
  components: {
    HeaderLayout,
    NavLayout,
    FooterLayout
}
}
</script>