export const pt_BR = {
    links: {
        home: 'Página Inicial',
        mission: 'Missão',
        services: 'Serviços',
        contact: 'Contato',
        privacy: 'Privacy Policy'
    },
    contact: {
        title: 'Contato',
        findUs: 'Ache-nos',
        btnWhatsapp: 'btn_whatsapp_ptBR.png'
    },
    img: {
        img1: '1_ptBR2.jpg',
        img2: '2_ptBR2.jpg',
        img3: '3_en2.jpg'
    },
    footer: {
        quickLinks: 'Links',
        bookAppointment: 'Marcar Consulta',
    },
    altsImgs: {
        btnWhatsapp: 'Inicar conversa no whatsapp',
        logoDiverse: 'Diverse Care Now'
    }
}