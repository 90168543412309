const contentEn = `
<p>IV therapy can deliver a range of health and wellness benefits, including but not limited to:</p>
<ul>
    <li>Promoting faster weight loss</li>
    <li>Curing hangover symptoms</li>
    <li>Treating certain nutrient deficiencies</li>
    <li>Cleansing your body of toxins and free radicals</li>
    <li>Increasing your energy levels</li>
    <li>Promoting better cardiovascular health</li>
    <li>Easing anxiety and promoting relaxation</li>
</ul>
`

const contentPtBR = `
<p>A terapia IV pode oferecer uma variedade de benef&iacute;cios de sa&uacute;de e bem-estar, incluindo, entre outros:</p>
<ul>
	<li>Promover uma perda de peso mais r&aacute;pida</li>
	<li>Curar sintomas de ressaca</li>
	<li>Tratamento de certas defici&ecirc;ncias nutricionais</li>
	<li>Limpando seu corpo de toxinas e radicais livres</li>
	<li>Aumentar seus n&iacute;veis de energia</li>
	<li>Promover uma melhor sa&uacute;de cardiovascular</li>
	<li>Aliviar a ansiedade e promover o relaxamento</li>
</ul>

`

export default { contentEn, contentPtBR }