import PrimaryContent from './PrimaryContent'
import TelemedicineContent from './TelemedicineContent'
import IMIVVitaminTherapiesContent from './IMIVVitaminTherapiesContent'
import PRPMicroneedlingFacialContent from './PRPMicroneedlingFacialContent'
import HormoneReplacementContent from './HormoneReplacementContent'
import EarLobeRepairContent from './EarLobeRepairContent'
import WeightLossContent from './WeightLossContent'
import EmsculptNeoContent from './EmsculptNeoContent'
import FacialHarmonizationContent from './FacialHarmonizationContent'
import PolyLLacticContent from './PolyLLacticContent'
import SclerotherapyContent from './SclerotherapyContent'
import DCNAcademyCursesContent from './DCNAcademyCursesContent'

export const servicesContent = [
    { 
        id: 1, 
        labelPtBR: 'Cuidados Primários/Pediatria', 
        labelEn: 'Primary Care/Pediatric', 
        nameParams: 'primary-care', 
        contentEn: PrimaryContent.contentEn, 
        contentPtBR: PrimaryContent.contentPtBR, 
        img: '1.jpg' 
    },
    { 
        id: 2, 
        labelPtBR: 'Telemedicina', 
        labelEn: 'Telemedicine', 
        nameParams: 'telemedicine', 
        contentEn: TelemedicineContent.contentEn, 
        contentPtBR: TelemedicineContent.contentPtBR, 
        img: '2.jpg' 
    },
    { 
        id: 3, 
        labelPtBR: 'IM / IV Terapias com Vitaminas ', 
        labelEn: 'IM/IV Vitamin Therapies', 
        nameParams: 'im-iv-vitamin-therapies', 
        contentEn: IMIVVitaminTherapiesContent.contentEn, 
        contentPtBR: IMIVVitaminTherapiesContent.contentPtBR, 
        img: '3.jpg' 
    },
    { 
        id: 4, 
        labelPtBR: 'PRP / Microagulhamento / Facial', 
        labelEn: 'PRP / Microneedling/ Facial', 
        nameParams: 'prp-microneedling-facial', 
        contentEn: PRPMicroneedlingFacialContent.contentEn, 
        contentPtBR: PRPMicroneedlingFacialContent.contentPtBR, 
        img: '4.jpg' 
    },
    { 
        id: 5, 
        labelPtBR: 'Reposição Hormonal/ Testosterona Club Shot', 
        labelEn: 'Hormone Replacement / Testosterone Club Shot', 
        nameParams: 'hormone-replacement-testosterone-club-shot', 
        contentEn: HormoneReplacementContent.contentEn, 
        contentPtBR: HormoneReplacementContent.contentPtBR, 
        img: '5.jpg' 
    },
    { 
        id: 6, 
        labelPtBR: 'Reparo do Lóbulo da Orelha / Remoção de Cisto', 
        labelEn: 'Ear Lobe Repair/ Cyst Removal', 
        nameParams: 'ear-lobe-repair-cyst-removal', 
        contentEn: EarLobeRepairContent.contentEn, 
        contentPtBR: EarLobeRepairContent.contentPtBR, 
        img: '6.jpg' 
    },
    { 
        id: 7, 
        labelPtBR: 'Perda de Peso / Redução de Gordura não Cirúrgica', 
        labelEn: 'Weight Loss / Non-Surgical Fat Reduction', 
        nameParams: 'weight-loss-non-surgical-fat-reduction', 
        contentEn: WeightLossContent.contentEn, 
        contentPtBR: WeightLossContent.contentPtBR, 
        img: '7.jpg' 
    },
    { 
        id: 8, 
        labelPtBR: 'EMSCULPT Neo', 
        labelEn: 'EMSCULPT Neo', 
        nameParams: 'emsculpt-neo', 
        contentEn: EmsculptNeoContent.contentEn, 
        contentPtBR: EmsculptNeoContent.contentPtBR, 
        img: '8.jpg' 
    },
    { 
        id: 9, 
        labelPtBR: 'Harmonização Facial', 
        labelEn: 'Facial Harmonization', 
        nameParams: 'facial-harmonization', 
        contentEn: FacialHarmonizationContent.contentEn, 
        contentPtBR: FacialHarmonizationContent.contentPtBR, 
        img: '9.jpg' 
    },
    { 
        id: 10, 
        labelPtBR: 'Elevador de Glúteos com Àcido Poli L-láctico', 
        labelEn: 'Poly L-Lactic Acid Gluteus Lift', 
        nameParams: 'poly-l-lactic-acid-gluteus-lift', 
        contentEn: PolyLLacticContent.contentEn, 
        contentPtBR: PolyLLacticContent.contentPtBR, 
        img: '10.jpg' 
    },
    { 
        id: 11, 
        labelPtBR: 'Escleroterapia / Depilação', 
        labelEn: 'Sclerotherapy/ Hair Removal', 
        nameParams: 'sclerotherapy-hair-removal', 
        contentEn: SclerotherapyContent.contentEn, 
        contentPtBR: SclerotherapyContent.contentPtBR, 
        img: '11.jpg' 
    },
    { 
        id: 13, 
        labelPtBR: 'DCN Academy Cursos', 
        labelEn: 'DCN Academy Curses', 
        nameParams: 'dcn-academy-cursos', 
        contentEn: DCNAcademyCursesContent.contentEn, 
        contentPtBR: DCNAcademyCursesContent.contentPtBR, 
        img: '13.jpg' 
    }
]