const contentEn = `<p>What areas on the body can the EMSCUPLT NEO benefit?</p>

<ul>
	<li>The EMSCULPT NEO has many benefits in body contouring and can be used in multiple areas of your body.</li>
	<li>It builds, and defines muscles, reduces fat deposits, and promotes moderate skin tightening.</li>
	<li>EMSCULPT NEO specializes in body contouring on these areas of the body: Abdomen, Buttocks, Thighs, Calves, Biceps, and Triceps.</li>
	<li>The procedure time for EMSCULPT NEO can be as little as 30 minutes of actual treatment</li>
</ul>
`

const contentPtBR = `
	<p>&nbsp;Quais &aacute;reas do corpo o EMSCUPLT NEO pode beneficiar?</p>
	<p>O EMSCULPT NEO tem muitos benef&iacute;cios no contorno corporal e pode ser usado em v&aacute;rias &aacute;reas do seu corpo.</p>
	<p>Ele constr&oacute;i e define os m&uacute;sculos, reduz os dep&oacute;sitos de gordura e promove o endurecimento moderado da pele.</p>
	<p>EMSCULPT NEO &eacute; especialista em contorno corporal nestas &aacute;reas do corpo: Abd&ocirc;men, N&aacute;degas, Coxas, Panturrilhas, B&iacute;ceps e Tr&iacute;ceps.</p>
	<p>O tempo de procedimento para EMSCULPT NEO pode ser de apenas 30 minutos de tratamento real</p>

`

export default { contentEn, contentPtBR }