import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import VueScrollTo from 'vue-scrollto'
import { createI18n } from 'vue-i18n'

//arquivos de idioma para traduzir o site
import { pt_BR } from '../src/locales/pt_BR'
import { en } from '../src/locales/en'

const languages = {
    pt_BR,
    en
}

const i18n = createI18n({
    legacy: false,
    locale: 'en',
    fallbackLocale: 'en',
    messages: languages
})

createApp(App)
.use(router)
.use(VueScrollTo)
.use(i18n)
.mount('#app')
