const contentEn = `
<ul>
	<li>Routine consultations and physical exams&nbsp;</li>
	<li>Service for children from one year old, teenagers and adults with no age limit;</li>
	<li>PRESCRIPTIONS MEDICATIONS INCLUDING CONTROLLED</li>
	<li>DEPRESSION AND ANXIETY</li>
	<li>OBESITY AND WEIGHT LOSS</li>
	<li>INFECTIOUS DISEASES, FLU, VIRUSES AND ALLERGIES</li>
	<li>MEDICATION REFILLS</li>
	<li>DIABETES, HIGH BLOOD PRESSURE ,CHOLESTEROL, ANEMIA, INFECTIONS AND OTHER CONDITIONS</li>
	<li>HORMONAL TREATMENTS AND PELLETS</li>
	<li>CHECK-UPS - LABORATORY SERVICES-PROMOTIONAL EXAM PACKAGES</li>
	<li>CYSTS REMOVAL AND EAR LOBE REPAIR</li>
	<li>BOTOX, FILLER AND DO</li>
	<li>MINOR SURGERY AND SCLEROTHERAPY</li>
	<li>MESOTHERAPY - FAT BURNING INJECTIONS</li>
	<li>IUD INSERT/REMOVAL AND NEXPLANON</li>
	<li>NOTES, FORMS, LETTERS, EXEMPTIONS AND PARKING PERMIT DISABILITY</li>
</ul>
	`

const contentPtBR = `
<ul>
	<li>Consultas de rotina e &nbsp;Exames F&iacute;sicos;</li>
	<li>Atendimento para crian&ccedil;as a partir de um ano, adolescentes e adultos sem idade limite;</li>
	<li>PRESCRI&Ccedil;&Atilde;O DE MEDICAMENTOS INCLUINDO CONTROLADOS; DEPRESS&Atilde;O E ANSIEDADE; OBESIDADE E PERDA DE PESO;</li>
	<li>REFILS DE MEDICAMENTOS;</li>
	<li>DIABETES, PRESS&Atilde;O ALTA, COLESTEROL, ANEMIA, INFECC&Otilde;ES E ATENDIMENTOS GINECOL&Oacute;GICOS;</li>
	<li>TRATAMENTOS HORMONAIS E CHIP DA BELEZA (PELLETS);</li>
	<li>CHECK-UPS - SERVI&Ccedil;OS LABORATORIAIS PACOTES PROMOCIONAIS DE EXAMES</li>
	<li>REMO&Ccedil;&Atilde;O DE CISTOS E REPARO DE LOBULO DA ORELHA</li>
	<li>BOTOX, PREENCHIMENTO E FIOS DE PDO;</li>
	<li>PEQUENAS CIRURGIAS E TRATAMENTO PARA VARISES E MICRO VASOS</li>
	<li>IMPLANTE CONTRACEPTIVO E COLOCAC&Atilde;O E RETIRADA DE DIU</li>
	<li>MESOTERAPIA - INJE&Ccedil;OES DE QUEIMA DE GORDURA</li>
	<li>EXAME FISICO PARA ESCOLA, ATESTADOS E FORMUL&Aacute;RIOS</li>
</ul>
`

export default { contentEn, contentPtBR }