<template>
    <BannerHomeComponent/>
    <ServicesComponent/>
    <ContactComponent/>
</template>
<script>
import BannerHomeComponent from '../components/BannerHomeComponent'
import ServicesComponent from '../components/ServicesComponent'
import ContactComponent from '../components/ContactComponent'

export default {
  name: 'App',
  components: {
    ContactComponent,
    ServicesComponent,
    BannerHomeComponent
}
}
</script>