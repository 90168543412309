<template>
  <MainLayout/>
</template>

<script>
import MainLayout from './layouts/MainLayout'

export default {
  name: 'App',
  components: {
    MainLayout
  }
}
</script>
