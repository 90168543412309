import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../pages/HomePage'
import MissionPage from '../pages/MissionPage'
import ServiceDetailsPage from '../pages/ServiceDetailsPage'
import PrivacyPage from '../pages/PrivacyPage.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/mission',
    name: 'Mission',
    component: MissionPage
  },
  {
    path: '/privacy',
    name: 'Privacy Policy',
    component: PrivacyPage
  },
  {
    path: '/service/:service?',
    name: 'Service',
    component: ServiceDetailsPage,
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router