const contentEn = `<p><strong>Testosterone Replacement Therapy</strong></p>

<p>Testosterone levels can affect mood, muscle mass, energy, sex drive, and erections. For men who aren&rsquo;t producing an appropriate amount of testosterone, replacement therapies use a number of different methods to reintroduce the male hormone into the body. This includes gels, injections, patches and pellet implants. Symptoms of low testosterone are sometimes obvious, but they also can be subtle. Testosterone levels decline naturally in men as they age over decades. But certain conditions can also lead to an abnormally low level.</p>

<p>Symptoms of low testosterone include:</p>

<ul>
	<li>Low sex drive (libido)</li>
	<li>Erectile dysfunction</li>
	<li>Fatigue and poor energy level</li>
	<li>Decreased muscle mass</li>
	<li>Body and facial hair loss</li>
	<li>Difficulty concentrating</li>
	<li>Depression</li>
	<li>Irritability</li>
	<li>Low sense of well-being</li>
</ul>

<p>Our hormone replacement therapy providers and staff ensure a treatment that will work best for you while providing the best results.</p>

<p>&nbsp;</p>

<p><strong>Hormone Replacement with Pellet </strong></p>

<p>One of our treatment options are Pellet Implants. Testosterone implants successfully treat symptoms in both men and women. The implants are implanted under the skin, consistently releasing levels of hormones without any fluctuations. Pellets or chips are made up of hormones that are pressed very small measuring 3 millimeters. Testosterone implants deliver consistent, healthy levels of hormones for 3-6 months without any ups and downs. Hormone replacement is important for optimal health and disease prevention. Pellet treatment for hormones is useful against a variety of ailments. Among the advantages are:</p>

<ul>
	<li>Emotional control</li>
	<li>Menstrual headaches and migraines</li>
	<li>Enhances focus and memory</li>
	<li>Alleviates stress and depression</li>
	<li>Helps eliminate vaginal dryness</li>
	<li>Frequent urination and urgency</li>
	<li>Improves bone density and muscular strength</li>
	<li>Reduces incontinence</li>
	<li>Fat loss</li>
	<li>Increase libido</li>
</ul>
`

const contentPtBR = `
<p>Os n&iacute;veis de testosterona podem afetar o humor, a massa muscular, a energia, o desejo sexual e as ere&ccedil;&otilde;es. &nbsp;Para homens que n&atilde;o est&atilde;o produzindo uma quantidade adequada de testosterona, as terapias de reposi&ccedil;&atilde;o usam v&aacute;rios m&eacute;todos diferentes para reintroduzir o horm&ocirc;nio masculino no corpo. &nbsp;Isso inclui g&eacute;is, inje&ccedil;&otilde;es, patches e implantes de pellets. &nbsp;Os sintomas de baixa testosterona &agrave;s vezes s&atilde;o &oacute;bvios, mas tamb&eacute;m podem ser sutis. &nbsp;Os n&iacute;veis de testosterona diminuem naturalmente nos homens &agrave; medida que envelhecem ao longo de d&eacute;cadas. &nbsp;Mas certas condi&ccedil;&otilde;es tamb&eacute;m podem levar a um n&iacute;vel anormalmente baixo.</p>

<p>&nbsp;Os sintomas de baixa testosterona incluem:</p>

<ul>
	<li>Baixo desejo sexual (libido)</li>
	<li>Disfun&ccedil;&atilde;o er&eacute;til</li>
	<li>Fadiga e baixo n&iacute;vel de energia</li>
	<li>Diminui&ccedil;&atilde;o da massa muscular</li>
	<li>Perda de pelos corporais e faciais</li>
	<li>Dificuldade de concentra&ccedil;&atilde;o</li>
	<li>Depress&atilde;o</li>
	<li>Irritabilidade</li>
	<li>Baixa sensa&ccedil;&atilde;o de bem-estar</li>
	<li>Nossos provedores e equipe de terapia de reposi&ccedil;&atilde;o hormonal garantem um tratamento que funcionar&aacute; melhor para voc&ecirc;, proporcionando os melhores resultados.</li>
</ul>

<p><strong>Terapia de p&iacute;lulas de testosterona</strong></p>

<p>Uma das nossas op&ccedil;&otilde;es de tratamento s&atilde;o os Implantes de Pellet. Os implantes de testosterona tratam com sucesso os sintomas em homens e mulheres. Os implantes s&atilde;o implantados sob a pele, liberando consistentemente os n&iacute;veis de horm&ocirc;nios sem quaisquer flutua&ccedil;&otilde;es. Pellets ou chips s&atilde;o formados por horm&ocirc;nios que s&atilde;o prensados bem pequenos medindo 3 mil&iacute;metros. Os implantes de testosterona fornecem n&iacute;veis consistentes e saud&aacute;veis de horm&ocirc;nios por 3 a 6 meses, sem altos e baixos. A reposi&ccedil;&atilde;o hormonal &eacute; importante para uma &oacute;tima sa&uacute;de e preven&ccedil;&atilde;o de doen&ccedil;as. O tratamento com p&iacute;lulas para horm&ocirc;nios &eacute; &uacute;til contra uma variedade de doen&ccedil;as. Entre as vantagens est&atilde;o:</p>

<ul>
	<li>Controle emocional</li>
	<li>Dores de cabe&ccedil;a e enxaquecas menstruais</li>
	<li>Melhora o foco e a mem&oacute;ria</li>
	<li>Alivia o estresse e a depress&atilde;o</li>
	<li>Ajuda a eliminar a secura vaginal</li>
	<li>Mic&ccedil;&atilde;o frequente e urg&ecirc;ncia</li>
	<li>Melhora a densidade &oacute;ssea e a for&ccedil;a muscular</li>
	<li>Reduz a incontin&ecirc;ncia</li>
	<li>Perda de peso</li>
	<li>Aumentar a libido</li>
</ul>
`

export default { contentEn, contentPtBR }