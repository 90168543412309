<template>
    <section 
        class="news-detail-header-section text-center" 
        :style="`background-image: url('/images/services/${service.img}')`">
        <div class="section-overlay"></div>
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-12">
                    <h1 class="text-white">{{ $i18n.locale == 'pt_BR' ? service.labelPtBR : service.labelEn  }}</h1>
                </div>
            </div>
        </div>
    </section>
    <section class="news-section section-padding">
        <div class="container">
            <div class="row">
                <div class="col-12" style="text-transform: uppercase" >
                    <div 
                        v-if="$i18n.locale == 'en'"
                        class="news-block" 
                        v-html="service.contentEn">
                    </div>
                    <div 
                        v-else
                        class="news-block" 
                        v-html="service.contentPtBR">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { useRoute } from 'vue-router'
import { watch, ref } from "vue";
import { servicesContent } from './ServicesDetails/ServicesContent'

export default({
    setup() {
        let service = ref({})
        const route = useRoute()
        
        const initData = () => {
            const serviceParams = route.params.service
        
            function retornaServiceContent (value){
                if (value.nameParams == serviceParams)
                    return value;
            }
            let resultService = servicesContent.filter(retornaServiceContent)
            service.value = resultService[0]
        }
        initData()
       
        watch(
            () => route.params,
            () => {
                initData()
            }
        )

        return {
            service
        }
    }
})
</script>
